
import Vue from "vue";
import ShareDropdown from "@/components/ShareDropdown.vue";

export default Vue.extend({
  name: "MentorcastCertificate",
  components: {
    ShareDropdown
  },
  props: {
    certificate: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      url: `${process.env.VUE_APP_HOST_URL}/mentorcasts/${this.certificate.id}/certificate`,
      maxRatingValue: 10,
      certificateLink: ""
    };
  },
  mounted() {
    this.type === "mentorcast"
      ? (this.certificateLink = `/mentorcasts/${this.certificate.id}/certificate`)
      : (this.certificateLink = `/mentoring-program/${this.$route.params.id}/certificate/${this.certificate.id}`);
  },
  methods: {
    onView() {
      window.open(this.certificate.certificate_path, "_blank");
    },
    onDownload() {
      window.open(this.certificate.certificate_download_path, "_blank");
    }
  }
});
