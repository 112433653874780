
import Vue from "vue";
import { Mentorcast } from "@/types/interfaces";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ShareDropdown",
  props: {
    text: String,
    hideoverflow: Boolean,
    data: {
      type: Object as () => Mentorcast
    },
    id: {
      type: Number
    },
    url: {
      required: true,
      type: String
    },
    type: {
      default: "mentorcast",
      type: String
    },
    isOutline: {
      type: Boolean
    }
  },
  data() {
    return {
      title: this.data?.title || this.data.description,
      description: this.data.description,
      networks: [
        (this as any).$t("setting.shareDropdownLinkedin"),
        (this as any).$t("setting.shareDropdownTwitter"),
        (this as any).$t("setting.shareDropdownFacebook")
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {
    copyURL() {
      navigator.clipboard.writeText(this.url);
      let message = (this as any).$t("setting.shareURLCopySuccessMessage");
      if (this.type === "library") {
        message = (this as any).$t("setting.shareLibraryURLCopySuccessMessage");
      }
      if (this.type === "mentorcast") {
        message = (this as any).$t(
          "setting.shareMentorcastURLCopySuccessMessage"
        );
      }
      (this as any).$alertify.success(message);
    },
    onShare() {
      this.$store
        .dispatch("profile/SHARE_MENTORCAST_IN_RECENT_ACTIVITY", this.id)
        .then(() => {
          (this as any).$alertify.success(
            (this as any).$t("setting.shareMentorcastURLSharedSuccessMessage")
          );
          this.$router.push("/profile");
        });
    },
    capitalize(s: string) {
      const value = s && s[0].toUpperCase() + s.slice(1);
      return value.replace("_", " ");
    },
    onOver() {
      (this.$refs as any).dropdown.visible = true;
    },
    onLeave() {
      (this.$refs as any).dropdown.visible = false;
    }
  }
});
