var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100 saif-test",on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown",attrs:{"variant":"link","left":"","toggle-class":`text-decoration-none ${
        ['certificate', 'library'].includes(_vm.type)
          ? (_vm.isOutline
              ? 'btn-outline-primary text-primary'
              : 'bg-primary text-white') + ' main-btn px-9'
          : 'text-color-2 share-btn'
      }`,"no-caret":"","menu-class":"share-dropdown"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"share-btn-icon",class:{
          'share-btn-icon-white': _vm.type === 'certificate' && !_vm.isOutline
        }},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/share.svg`),"height":"18","width":"18"}})],1),(_vm.text)?_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.text))]):_vm._e()]},proxy:true}])},[_vm._l((_vm.networks),function(network,key){return _c('b-dropdown-item',{key:key,attrs:{"link-class":"d-flex align-items-center font-size-12 share-dropdown-item"}},[_c('inline-svg',{staticClass:"d-block mr-4",attrs:{"src":require(`@/assets/icons/${network}-share.svg`),"height":"14","width":"14"}}),_c('ShareNetwork',{attrs:{"network":network,"url":_vm.url,"title":_vm.title ? _vm.title : '',"description":_vm.description,"quote":_vm.description}},[_vm._v(" "+_vm._s(_vm.capitalize(network))+" ")])],1)}),(_vm.type == 'mentorcast' && _vm.profile.id)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center font-size-12 share-dropdown-item"}},[_c('inline-svg',{staticClass:"d-block mr-4",attrs:{"src":require(`@/assets/icons/mentorice.svg`),"height":"14","width":"14"}}),_c('span',{on:{"click":_vm.onShare}},[_vm._v(" "+_vm._s(_vm.$t("mentorcastHero.sharerdropdownModelMentorice"))+" ")])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center font-size-12 share-dropdown-item"}},[_c('inline-svg',{staticClass:"d-block mr-4",attrs:{"src":require(`@/assets/icons/copy.svg`),"height":"14","width":"14"}}),_c('span',{on:{"click":function($event){return _vm.copyURL()}}},[_vm._v(" "+_vm._s(_vm.$t("mentorcastHero.sharerdropdownModelCopyURL"))+" ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }